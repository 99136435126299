import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

/*
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
});
*/
const app = firebase.initializeApp({
  apiKey: "AIzaSyCMp2eNX93NHWw5JXbhDf8ilBf7QjcxME8",
  authDomain: "dsadmin-c3dfd.firebaseapp.com",
  projectId: "dsadmin-c3dfd",
  storageBucket: "dsadmin-c3dfd.appspot.com",
  messagingSenderId: "464991866371",
  appId: "1:464991866371:web:289f07082537a753d50836"
});

export const auth = app.auth()
export default app