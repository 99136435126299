import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaHome, FaUserCircle } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import { Alert, Button } from "react-bootstrap";
import Note from "./Note";

export default function Dashboard() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      setError(err.message.replace(/Firebase/g, "Error"));
    }
  }

  function addNote() {
    setNotes([
      ...notes,
      {
        mode: "new",
        text: ""
      }
    ])
    console.log(notes)
  }

  return (
    <>
      <div className="d-flex">
        <div
          className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
          style={{ width: "280px", height: "100vh" }}
        >
          <span>{currentUser.email}</span>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="d-flex align-items-center">
              <FaHome />
              <Link to="/" className="nav-link text-white">
                Home
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <FaUserCircle />
              <Link to="/update-profile" className="nav-link text-white">
                Update Profile
              </Link>
            </li>
            <li className="d-flex align-items-center">
              <FaSignOutAlt />
              <Link
                to="#"
                className="nav-link text-white"
                onClick={handleLogout}
              >
                Log Out
              </Link>
            </li>
          </ul>
        </div>
        <div className="p-3">
          {error && (
            <Alert variant="danger" className="text-center">
              {error}
            </Alert>
          )}
          <Button type="button" onClick={addNote}>Add Notes</Button>
          <div className="py-2">
            {
              notes.map((note) => {
                return <Note data={note} />
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}
