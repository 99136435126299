import React from "react";
import { Button } from "react-bootstrap";

export default function Note(props) {
  const data = props.data;
  return (
    <>
      {data.mode === "new" ? (
        <div className="d-flex my-2">
            <input type="text" />
            <Button type="button mx-2">Add</Button>
            <Button type="button" className="btn-danger">Cancel</Button>
        </div>
      ) : data.mode === "edit" ? (
        <div>edit note</div>
      ) : (
        <div>display note</div>
      )}
    </>
  );
}
